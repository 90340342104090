/* .node__root {
  stroke: none !important;
}
.node__branch {
  stroke: none !important;
}
.node__leaf {
  stroke: none !important;
} */

.add-quest {
  height: 80px;
  width: 80px;
  background-color: #00b2ff;
  border-radius: 50%;
  font-size: 80px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #caf3ff;
  border-radius: 5px;
}

div::-webkit-scrollbar-thumb {
  background-color: #9fa9bd;
  border-radius: 5px;
}

div::-webkit-scrollbar {
  width: 0.5em;
  border-radius: 5px;
}

.delete-question-btn {
  color: #d94e4e;
  outline: none;
  border: none;
  border-radius: 5px;
  height: 20px;
  width: 50px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
.edit-question-btn {
  color: #0c80c8;
  outline: none;
  border: none;
  border-radius: 5px;
  height: 20px;
  width: 50px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
.com-option {
  padding: 5px 10px;
  background-color: #00b2ff;
  border-radius: 20px;
  color: white;
  font-size: 12px;
  text-align: center;
  margin: 5px;
}

.incom-option {
  padding: 5px 10px;
  background-color: #ff4d4d;
  border-radius: 20px;
  color: white;
  font-size: 12px;
  text-align: center;
  margin: 5px;
}

.option-node {
  width: 200px;
  padding: 5px;
  border-radius: 50px;
  background: #00b2ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  text-align: center;
  border: none;
  border-width: 0px;
}

.conditional-option-node {
  width: 200px;
  padding: 5px;
  border-radius: 50px;
  background: #a8c3cc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  text-align: center;
  border: none;
  border-width: 0px;
}

.dead-option-node {
  width: 200px;
  padding: 5px;
  border-radius: 50px;
  background: #cc0000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  text-align: center;
}

.text-updater-node-question {
  width: 200px;
  border: 1px solid black;
  padding: 5px;
  border-radius: 5px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  text-align: center;
  font-weight: bold;
  color: white;
  font-size: 12px;
}

.text-updater-node label {
  display: block;
  color: #777;
  font-size: 12px;
}

.color-white {
  color: white !important;
}

.toolbar-del {
  color: red;
  cursor: pointer;
  width: 80px;
  line-height: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  background: white;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid red;
}

.toolbar-add {
  color: green;
  cursor: pointer;
  width: 100px;
  line-height: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  background: white;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid green;
}

.toolbar-edit {
  color: blue;
  cursor: pointer;
  width: 80px;
  line-height: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  background: white;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid blue;
}
.toolbar-attach {
  color: #a6a832;
  cursor: pointer;
  width: 80px;
  line-height: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  background: white;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #a6a832;
}

.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output,
.react-flow__node-group {
  border: none;
  border-width: 0px !important;
}

.answer-multiple {
  border: 1px solid #36afff !important;
  border-radius: 5px;
  padding: 3px 10px !important;
  margin-bottom: 20px !important;
  width: 100%;
}

.logo-smallest {
  width: 20px;
}
.questions-list {
  list-style: none;
  border: 1px solid #36afff !important;
  padding: 6px;
  border-radius: 5px;
  cursor: pointer;
}

.active-question {
  list-style: none;
  border: 1px solid #36afff !important;
  background: #36afff;
  color: white;
  padding: 6px;
  border-radius: 5px;
  cursor: pointer;
}

.panel-buttons {
  border-radius: 10px;
  width: 25px;
  height: 8px;
  margin-right: 8px;
}

.font-10 {
  font-size: 10px !important;
}
