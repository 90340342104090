.bold {
  font-weight: 600 !important;
}

.font-16 {
  font-size: 16px;
}

.headings {
  background-color: #d4f2ff;
  padding: 10px;
}

.blue-add-question-btn-active {
  background-color: #00b2ff;
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
  height: 30px;
  width: 150px;
}

.blue-add-question-btn-inactive {
  background-color: #f2f2f2;
  color: black;
  outline: none;
  border: none;
  border-radius: 5px;
  height: 30px;
  width: 150px;
  cursor: not-allowed !important;
}

.blue-add-question-btn-2-active {
  background-color: #00b2ff;
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
  height: 30px;
  width: 150px;
}

.blue-add-question-btn-2-inactive {
  background-color: #f2f2f2;
  color: black;
  outline: none;
  border: none;
  border-radius: 5px;
  height: 30px;
  width: 150px;
  cursor: not-allowed !important;
}

.custom-input {
  border: 1px solid #d1d1d1 !important;
  border-radius: 5px;
  background-color: #fafafa;
}
.custom-input:focus {
  border: 1px solid #d1d1d1;
}
.cursor-pointer {
  cursor: pointer;
}

.red {
  color: red;
}
.green {
  color: green;
}

.textarea-styles {
  width: 100%;
  padding: 10px;
  outline: none;
  background: #fafafa;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
}

.color-blue {
  color: #00b2ff !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.wd-10 {
  width: 10%;
}

.wd-15 {
  width: 15%;
}

.wd-55 {
  width: 55%;
}

.wd-20 {
  width: 20%;
}

.wd-60 {
  width: 60%;
}

.wd-80 {
  width: 80%;
}

.wd-40 {
  width: 40%;
}

.bd {
  border-bottom: 1px solid #dbdbdb;
}

.wd-700 {
  width: 700px;
}

.wd-400 {
  width: 400px;
}

.custom-table td {
  height: 50px;
  display: flex;
  align-items: center;
}

.custom-table tr {
  height: 50px;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding: 0px 10px;
  background-color: white;
  border-radius: 10px;
}

.question-node {
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  word-break: break-word;
  padding: 20px;
  color: black;
}

.question-level {
  font-size: 20px;
  font-weight: bold;
}

.tree-heading {
  font-size: 39px;
  font-weight: bold;
  margin-bottom: 50px;
}

.rd3t-label__title {
  transform: translate(-100px, -25px);
}

.rd3t-label__attributes {
  transform: translate(-100px, 20px);
}

.selected-node {
  background: #d5e7f2;
}
.unselected-node {
  background: #f2f2f2;
}

.tree-box {
  box-shadow: 1px 2px 9px 2px rgba(0, 0, 0, 0.15);
  /* padding: 5px; */
  background: #f2f2f2 !important;
  width: fit-content;
}

.tree-box .Mui-selected {
  background: #f2f2f2;
}

.modal-question-heading {
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 5px;
}
.MuiBox-root .MuiOutlinedInput-root {
  background-color: #fafafa !important;
  border-radius: 12px;
}
