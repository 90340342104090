// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(../fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: Montserrat-Regular;
  src: url(../fonts/Montserrat-Regular.ttf);
}
*,
a,
p,
button {
  font-family: Montserrat-Regular !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat-Bold !important;
}
header {
  padding-right: 15px !important;
  z-index: 999 !important;
}
.css-88v8r5 {
  background-color: #f2f2f2 !important;
}
.css-n4mf1r {
  background: #36afff !important;
  justify-content: space-around !important;
}
.scrollbar-container {
  .MuiList-root {
    .MuiListItemButton-root.Mui-selected {
      border-radius: 0px;
      border-left: 4px solid;
    }
    .MuiListItemButton-root:hover {
      border-radius: 0px;
    }
  }
}
.MuiToolbar-root {
  padding: 0px;
}
.css-jo89mv {
  margin-right: 0px;
  background-color: #f2f2f2 !important;
}
.MuiBox-root {
  .MuiOutlinedInput-root {
    background-color: #c3e7ff80;
    border-radius: 100px;
    width: 100%;
    flex-direction: row-reverse !important;
  }
  :hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  .icon-tabler-search {
    color: #36afff;
  }
  fieldset {
    border: none !important;
    border-color: transparent !important;
    border-radius: 100px !important;
  }
}

.total-consultation {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  height: 100%;
  .icon {
    img {
      width: 45px;
    }
  }
  h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .clock {
    margin-left: 10px;
    p {
      font-size: 12px;
      margin-bottom: 0px;
      color: #034562;
    }
    svg {
      color: #36afff;
      font-size: 18px;
    }
  }
  .consult-images {
    margin-top: 20px;
    p {
      margin-bottom: 0px !important;
      color: #034562;
      font-size: 13px;
      margin-left: 3px !important;
    }
    img {
      width: 55px;
    }
  }
}
.total-prescribe {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
  height: 100%;
  display: flex;
  .icon {
    img {
      width: 45px;
    }
  }
  h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .clock {
    margin-left: 10px;
    p {
      font-size: 12px;
      margin-bottom: 0px;
      color: #034562;
    }
    svg {
      color: #ff5d48;
      font-size: 18px;
    }
  }
  .consult-images {
    margin-top: 20px;
    p {
      margin-bottom: 0px !important;
      color: #ff5d48;
      font-size: 13px;
      margin-left: 3px !important;
    }
    img {
      width: 55px;
    }
  }
}
.main-page {
  .MuiCardContent-root:last-child {
    padding-bottom: 10px !important;
  }
  .MuiCardContent-root {
    padding: 11px !important;
  }
  .MuiFormControl-root {
    label {
      top: -8px !important;
    }
    .MuiOutlinedInput-root {
      input {
        border-radius: 3px !important;
        padding: 8px !important;
      }
    }
  }
  // .css-p2k78,.css-1py20bt-MuiInputBase-input-MuiOutlinedInput-input{
  //     padding: 10px !important;
  //     border-radius: 5px !important;
  // }
}
.bar-chart {
  margin-top: 10px !important;
  // display: flex;
  height: 100%;
  border: none;
  @media screen and (max-width: 768px) {
    display: block !important;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 0px !important;
  }
  .stats {
    font-size: 12px;
    @media screen and (max-width: 1024px) {
      font-size: 13px !important;
    }
  }
  .apexcharts-legend {
    display: none !important;
  }
  .sorting {
    border: 1px solid #c8d4db;
    font-size: 11px;
    padding: 3px;
    border-radius: 5px;
    select {
      background: transparent;
      border: none;
      outline: 0px;
      font-weight: bold;
      font-size: 11px;
    }
  }
}
.login {
  background-color: #f2f2f2;
  direction: ltr;
  .row {
    --bs-gutter-x: 0px !important;
    .blue-clr {
      background-color: #36afff;
      padding: 50px 15px;
      height: 100vh;
      display: flex;
      align-items: center;
      position: relative;
      .pattern {
        position: absolute;
        top: 4%;
        right: 9%;
        transform: translate(20px, -9px);
        img {
          width: 210px;
        }
      }
      .logo {
        padding-bottom: 20px;
        img {
          width: 110px;
        }
      }
      .mobile-img {
        img {
          margin: 30px 0px;
          width: 75%;
        }
      }
      .welcome {
        h1 {
          text-align: center;
          color: white;
          font-size: 35px;
          font-family: Montserrat-Bold;
        }
      }
    }
    .gray-clr {
      background-color: #f2f2f2;
      padding: 50px 15px !important;
      height: 100vh !important;
      display: flex;
      align-items: center;
      .welcome {
        h3 {
          font-size: 29px;
          color: #034562;
          font-family: Montserrat-Bold;
        }
        p {
          font-size: 15px;
          color: #034562;
          margin-top: 5px;
          margin-bottom: 40px;
          font-family: Montserrat-Regular;
        }
      }
      .password {
        font-family: Montserrat-Regular;
        label {
          margin-bottom: 10px;
          font-size: 15px;
          font-family: Montserrat-Regular;
        }
        .MuiInput-root {
          width: 100%;
          background: white !important;
          padding: 13px 20px;
          border-radius: 7px;
          border: transparent;
          box-shadow: 1px 1px 12px 2px #80808036;
          margin-bottom: 15px;
        }
        .MuiInput-root:hover:not(.Mui-disabled):before {
          border-bottom: none;
        }
        .MuiInput-root:after {
          border-bottom: none;
        }
        .MuiInput-root:before {
          border-bottom: none;
        }
      }
      .email {
        font-family: Montserrat-Regular;
        label {
          margin-bottom: 10px;
          font-size: 15px;
          font-family: Montserrat-Regular;
        }
        input {
          width: 100%;
          background: white !important;
          padding: 13px 20px;
          border-radius: 7px;
          border: transparent;
          margin-bottom: 15px;
          outline: none;
          box-shadow: 1px 1px 12px 2px #80808036;
          font-family: Montserrat-Regular;
        }
        ::placeholder {
          color: #dadada;
        }
      }
      .forget {
        text-align: end;
        a {
          color: #ff5d48;
          text-decoration: none;
          font-family: Montserrat-Regular;
        }
      }
      .login-btn {
        button {
          width: 100%;
          padding: 9px;
          color: white;
          background: #36afff;
          box-shadow: none;
          margin-top: 25px;
          font-size: 16px;
          text-transform: uppercase;
          font-weight: 300;
          letter-spacing: 1px;
          font-family: Montserrat-Regular;
        }
      }
    }
  }
}
.Order-Details {
  font-family: Montserrat-Regular;
  color: #001847;
  .sorting {
    font-size: 15px;
    label {
      color: #8387a5;
      letter-spacing: 1px;
    }
    select {
      background: transparent;
      border: none;
      outline: 0px;
      font-weight: bold;
      color: #001847;
      letter-spacing: 1px;
      font-size: 14px;
    }
  }
  .approval-btns {
    padding: 0px;
    margin-left: 10px;
    color: #8387a5;
  }
  h4 {
    margin-bottom: 0px;
  }
  .sc-dlVxhl {
    .sc-fKVqWL {
      .rdt_Table {
        background-color: transparent !important;
        .sc-hGPBjI {
          .fvAeSn {
            min-width: auto;
          }
        }
        .sc-gsDKAQ {
          .rdt_TableHeadRow {
            background: white !important;
            border-radius: 10px !important;
            outline: none !important;
            border: none !important;
            .fvAeSn {
              min-width: 155px !important;
              font-size: 13px !important;
              color: #8387a5 !important;
            }
          }
        }
        .rdt_TableRow {
          padding: 20px 0px !important;
          border-bottom-color: #f2f2f2 !important;
          background: white !important;
          border-radius: 10px !important;
          margin-bottom: 15px !important;
          outline: none !important;
        }
        .rdt_TableHead {
          border-bottom-style: solid !important;
          border-bottom-width: 10px !important;
          border-bottom-color: rgb(242 242 242) !important;
        }
        .iAwKFK:hover {
          background-color: #ffffff !important;
        }
      }
    }
  }
  .rdt_Pagination {
    border-radius: 10px !important;
    border: none !important;
  }
  .user-data {
    h5 {
      font-size: 12px;
      margin-bottom: 1px;
      color: #001847;
    }
  }
  .user-data {
    p {
      font-size: 10px;
      margin-bottom: -5px;
      color: #8387a5;
      font-weight: bold;
    }
  }
  .date {
    h5 {
      font-size: 12px;
      margin-bottom: 1px;
      color: #001847;
    }
    .MuiFormControl-root {
      input {
        height: 20px !important;
        padding: 8px 5px !important;
        border-radius: 3px !important;
      }
      label {
        top: -6px !important;
      }
    }
  }

  .font-green {
    color: green !important;
  }
  .font-red {
    color: red !important;
  }
  .delete-question-btn {
    color: #d94e4e;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 20px;
    width: 50px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
  .edit-question-btn {
    color: #0c80c8;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 20px;
    width: 50px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
  .add-question-btn {
    background-color: #c3e7ff80;
    color: #36afff;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 30px;
    width: 150px;
    margin-right: 50px;
  }
  .add-question-btn-2 {
    background-color: #c3e7ff80;
    color: #36afff;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 30px;
    width: 150px;
    // margin-right: 50px;
  }
  .active-btn {
    background-color: #c3e7ff80;
    color: #36afff;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 3px 10px;
  }
  .inActive-btn {
    background-color: #e9bcaf;
    color: #84270d;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 3px 10px;
  }
  .edit-btn {
    background: transparent;
    border: none;
    // border-right: 1px solid lightgray;
    color: #72a1ff;
  }
  .delete-btn {
    background: transparent;
    border: none;
    color: #ff5d48;
  }
  .view-btn {
    background: transparent;
    border: none;
    color: #279847;
  }
  .edit-btn-primary {
    background: #36afff;
    color: #ffffff;
    padding: 10px 14px;
    border-radius: 5px;
    font-size: 13px;
    border: none;
  }
}
.side-content {
  background: white;
  padding: 15px;
  border-radius: 10px;
  .notifications {
    background-color: #c3e7ff80;
    padding: 20px 15px;
    border-radius: 10px;
    margin-top: 15px;
    h5 {
      margin-bottom: 0px;
      font-size: 16px;
      color: #001847;
    }
    a {
      margin-bottom: 0px;
      font-size: 16px;
      color: #36afff;
      text-decoration: none;
    }
    .notification {
      border-bottom: 1px solid #dedede;
      padding-bottom: 20px;
      margin-bottom: 20px;
      h6 {
        font-size: 14px;
        margin-bottom: 0px;
        color: #001847;
        .blue-dot {
          color: #36afff;
          font-size: 12px;
        }
        .orange-dot {
          color: #ff6d39;
          font-size: 12px;
        }
      }
      p {
        font-size: 12px;
        margin-bottom: 0px;
        color: #8387a5;
      }
      .arrow {
        svg {
          color: #8387a5;
          font-size: 14px;
        }
      }
    }
  }
  .upcoming-patients {
    h4 {
      color: #001847;
      font-size: 16px;
      margin-bottom: 15px;
      margin-top: 30px;
    }
    .upcoming-patient {
      background-color: #f1f5fe;
      padding: 20px 15px;
      border-radius: 10px;
      margin-top: 15px;
      .patient {
        img {
          width: 50px;
          height: 51px;
          border-radius: 8px;
          object-fit: cover;
          margin-right: 10px;
        }
        h5 {
          color: #001847;
          font-size: 14px;
          margin-bottom: 0px;
        }
        p {
          color: #8387a5;
          font-size: 12px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
.user-profile-name {
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 13px;
}
.donut {
  border-radius: 10px;
  background: #e1f3ff;
  padding: 15px 0px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    overflow-x: scroll;
  }
  h4 {
    padding: 0px 15px;
  }
  .donut-chart {
    position: relative;
  }
  .date {
    padding: 0px 15px;
    p {
      font-size: 12px;
      margin-bottom: 15px;
    }
  }
  .chart {
    .buttons {
      padding: 0px 15px;
      padding-bottom: 15px;
      padding-top: 20px;
      .sales {
        background-color: #ff5d4826;
        color: #ff5d48;
        box-shadow: none;
        font-size: 13px;
        margin-right: 10px;
      }
      .orders {
        background-color: #36afff33;
        color: #36afff;
        box-shadow: none;
        padding: 3px 15px;
      }
    }
  }
}

.user-Details {
  font-family: Montserrat-Regular;
  color: #001847;
  h4 {
    margin-top: 10px;
  }
  .tabs {
    .nav-tabs {
      .nav-link {
        color: #36afff;
        background: white;
        margin-right: 10px;
        @media screen and (max-width: 768px) {
          margin-bottom: 10px;
        }
      }
      .nav-item.show {
        .nav-link {
          color: #ffffff;
          background-color: #36afff;
          border-color: #36afff;
        }
      }
      .nav-link.active {
        color: #ffffff;
        background-color: #36afff;
        border-color: #36afff;
      }
    }
    .detail {
      margin-top: 40px;
      h5 {
        font-size: 14px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .css-88v8r5 {
    padding: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .css-88v8r5 {
    padding: 10px !important;
  }
  .login {
    .blue-clr {
      height: auto !important;
    }
  }
  .gray-clr {
    height: auto !important;
    padding: 30px 15px !important;
  }
}
@media screen and (max-width: 425px) {
  .blue-clr {
    display: none !important;
  }
}
.MuiDrawer-paper {
  top: 88px;
  z-index: 1;
}
.btn-primary {
  background: #36afff !important;
  border: none !important;
  &:hover {
    background: #36afff !important;
    color: white;
    border: none !important;
  }
}

.modal-content {
  .modal-header {
    background: #36afff;
    padding: 5px 1rem;
    .modal-title {
      font-size: 20px;
      color: white;
      font-family: Montserrat-Bold !important;
    }
  }
  .close {
    font-size: 30px;
    padding: 0px 10px;
    color: white;
    &:hover {
      color: white;
    }
  }
  .auth-form {
    .form-group {
      margin-bottom: 20px;
      label {
        strong {
          font-size: 1rem;
          color: #034562;
          font-weight: 600;
        }
      }
    }
    .btn-primary {
      background: #36afff;
      border: none;
    }
  }
}
.react-calendar {
  width: 100% !important;
  margin-top: 10px;
}
.availability-slots {
  margin-top: 10px;
  .checkbox {
    input[type='checkbox'] {
      position: relative;
      cursor: pointer;
    }
    input[type='checkbox']:before {
      content: '';
      display: block;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 0;
      left: 0;
      border: 2px solid #36afff;
      border-radius: 3px;
      background-color: white;
    }
    input[type='checkbox']:checked:after {
      content: '';
      display: block;
      width: 5px;
      height: 10px;
      border: solid #36afff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 2px;
      left: 6px;
    }
  }
  .time {
    p {
      margin: 0px 10px;
      font-size: 14px;
      font-weight: 600;
      color: #034562;
      width: 60px;
    }
  }
  .slots {
    .btn-primary {
      width: 48%;
      @media screen and (max-width: 768px) {
        width: 47%;
      }
    }
    .btn-outline-primary {
      width: 48%;
      @media screen and (max-width: 768px) {
        width: 47%;
      }
    }

    input[type='checkbox'].graphic {
      display: none;
    }

    input[type='checkbox'].graphic + label,
    input[type='checkbox'].graphic + label:after {
      padding: 4px 10px;
      display: inline-block;
    }
    input[type='checkbox'].graphic + label {
      position: relative;
      background-color: rgb(255 255 255);
      color: #034562;
      border: 1px solid rgb(54 175 255);
      border-radius: 4px;
      margin: 1px;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
      width: 65px;
      text-align: center;
    }
    input[type='checkbox'].graphic + label:after {
      position: absolute;
      /* style */
      top: -22%;
      left: -3px;
      font-size: 180%;
    }
    // input[type="checkbox"].graphic + label:hover:after,
    // input[type="checkbox"].graphic:checked + label:after {
    //     content: '\2714';
    // }

    // /* style */
    // input[type="checkbox"].graphic + label:hover:after {
    //   text-shadow: 0 0 7px rgba(255,255,255,0.1);
    //   color: rgba(0,0,0,0);
    // }
    // input[type="checkbox"].graphic:checked + label:hover:after {
    //   text-shadow: none;
    //   color: inherit;
    // }
    input[type='checkbox'].graphic + label:hover,
    input[type='checkbox'].graphic:checked + label:hover {
      border: 1px solid #36afff;
    }

    input[type='checkbox'].graphic + label:active,
    input[type='checkbox'].graphic:checked + label:active {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 0 7px 1px rgba(0, 0, 0, 0.5), inset 0px 0 3px 0 rgba(0, 0, 0, 0.4);
      border: none;
      margin: 2px;
    }
    input[type='checkbox'].graphic:checked + label {
      color: white;
      border: 1px solid #36afff;
      background-color: #36afff;
    }
  }
}
.appointment-details {
  h5 {
    font-size: 15px;
    color: #034562;
  }
  p {
    font-size: 15px;
    color: #034562;
  }
  .edit-btn-primary {
    background: #36afff;
    color: #ffffff;
    padding: 6px 10px;
    border-radius: 5px;
    font-size: 15px;
    border: none;
    font-weight: 600;
    letter-spacing: 1px;
    &:hover {
      color: white;
    }
  }
}

.rc {
  width: 511px !important;
  height: 350px !important;
  font-size: 20px !important;
}
@media screen and (max-width: 1299px) {
  .rc {
    width: 437px !important;
  }
}
@media screen and (max-width: 1024px) {
  .rc {
    width: 466px !important;
  }
}
@media screen and (max-width: 978px) {
  .rc {
    width: 350px !important;
  }
}
@media screen and (max-width: 768px) {
  .rc {
    width: 338px !important;
  }
}
@media screen and (max-width: 766px) {
  .align-date {
    text-align: center;
    margin-bottom: 20px;
  }
  .availability-slots {
    text-align: center;
  }
  .availability-slots .slots .flex-wrap {
    justify-content: center;
  }
}
@media screen and (max-width: 374px) {
  .rc {
    width: 289px !important;
  }
}
.MuiSelect-select {
  &:focus {
    border-radius: 12px;
  }
}
.AddclinicalPath {
  margin: 20px 0px;
  .form-group {
    p {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 600;
      margin-top: 20px;
    }
    .MuiSvgIcon-root {
      margin-left: 10px;
    }
    .form-control {
      font-weight: 500;
      background: #fafafa;
      padding: 15.5px 14px;
      border-radius: 12px;
      margin-bottom: 10px;
      &:focus {
        box-shadow: none;
        border-color: #d1d1d1;
      }
    }
  }
}
.element-to-print {
  background-color: #36afff;
  padding: 60px 0;
  .logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .user {
      width: 65px;
    }
    .logo {
      width: 90px;
    }
  }
  .box-1 {
    margin-top: 50px;
    .pds_box {
      // height: 100px;
      // overflow: hidden;
      h3 {
        color: #ffff;
        margin-bottom: 15px;
        font-size: 16px;
        height: 30px;
      }
      table {
        width: 100%;
        font-size: 12px;
      }
      tr {
        vertical-align: initial;
        line-height: 25px;
      }
      table tr:first-child th:first-child {
        border-top-left-radius: 13px;
        padding-top: 15px !important;
      }
      table tr:first-child td:last-child {
        border-top-right-radius: 13px;
        padding-top: 15px !important;
      }
      table tr:last-child th:first-child {
        border-bottom-left-radius: 13px;
        padding-bottom: 15px !important;
      }
      table tr:last-child td:last-child {
        border-bottom-right-radius: 13px;
        padding-bottom: 15px !important;
      }
      .thead {
        background-color: #ffff;
        width: 30%;
        padding: 0px 25px;
        font-weight: bold;
        color: #034562;
      }
      .tdata {
        background-color: #d7efff;
        padding: 0px 25px;
        width: 70%;
        font-weight: bold;
        color: #034562;
      }
    }
    .complaint {
      h3 {
        color: #ffff;
        margin-bottom: 15px;
        font-size: 16px;
        height: 30px;
      }
      .pain {
        background-color: #004361;
        border-radius: 13px;
        padding-top: 30px;
        align-items: center;
        justify-content: center;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 255px;
        h4 {
          font-size: 16px;
          max-width: 200px;
          color: #ffff;
          width: 100%;
          text-align: center;
        }
        .circle {
          // background-color: #ff5d48;
          border: 12px solid white;
          width: 150px;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          margin: 25px auto;
          text-align: center;
          h4 {
            color: #034562;
            font-size: 24px;
            margin: 0;
          }
        }
        @media screen and (max-width: 991px) {
          .circle {
            width: 100px !important;
            height: 100px !important;
          }
        }
      }
    }
  }
  .box-2 {
    .thead {
      background-color: #d7efff !important;
      width: 70% !important;
      padding: 12px 35px !important;
    }
    .tdata {
      background-color: #ffff !important;
      width: 30% !important;
      padding: 12px 35px !important;
    }
  }
  .box-3 {
    .thead {
      background-color: #d7efff !important;
      width: 62% !important;
      padding: 12px 35px !important;
    }
    .tdata {
      background-color: #ffff !important;
      width: 30% !important;
      padding: 12px 35px !important;
    }

    // .red{
    //     background-color: #FF2948 !important;
    // }
    // .orange {
    //     background-color: #ff8648 !important;
    // }
    // .yellow {
    //     background-color: #f9e300 !important;
    // }
    // .green {
    //     background-color: #7cc212 !important;
    // }
    // .blue {
    //     background-color: #1a8bf3 !important;
    // }
    // .gray{
    //     background-color: #e4e7ec !important;
    // }
    .p1 {
      background-color: #ff2948 !important;
    }
    .p2 {
      background-color: #ff8648 !important;
    }
    .p3 {
      background-color: #ffdf00 !important;
    }
    .p4 {
      background-color: #77c012 !important;
    }
    .p5 {
      background-color: #1d8bf4 !important;
    }
    .p0 {
      background-color: #e4e7ec !important;
    }
  }
  .box-5 {
    margin-top: 50px;
    h3 {
      color: #ffff;
      margin-bottom: 15px;
      font-size: 20px;
    }
    .guide_box {
      background: #d7efff;
      padding: 10px 10px;
      border-radius: 13px;
      p {
        padding: 10px;
        border-radius: 8px;
        display: inline-block;
        margin: 5px 15px 5px 0;
        width: 110px;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
      }
      .p1 {
        background-color: #ff2948 !important;
      }
      .p2 {
        background-color: #ff8648 !important;
      }
      .p3 {
        background-color: #ffdf00 !important;
      }
      .p4 {
        background-color: #77c012 !important;
      }
      .p5 {
        background-color: #1d8bf4 !important;
      }
      .p0 {
        background-color: #e4e7ec !important;
      }
    }
  }
}

.modal-body {
  &.admin-create-modal {
    .MuiSelect-select {
      padding: 8.5px 14px;
      background-color: #ffffff;
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: 0.375rem;
    }
  }
}
[dir='rtl'] {
  header {
    padding: 0 0 0 15px !important;
  }
  .text-end {
    text-align: left !important;
  }
  .Order-Details .approval-btns {
    margin: 0 10px 0 0;
  }
  .side-content .upcoming-patients .upcoming-patient .patient img {
    margin: 0 0 0 10px;
  }
  .side-content .notifications .notification .arrow {
    transform: scaleX(-1);
  }
  .MuiAvatar-circular {
    margin-right: 8px !important;
    margin-left: 0 !important;
  }
  .user-profile-name {
    margin-right: 10px !important;
    margin-left: 0 !important;
  }
  .MuiDrawer-paper {
    right: 0;
    left: unset;
    transform: translateX(100%) !important;
  }
  .nav-side-wrapper > .MuiDrawer-root > .MuiPaper-root {
    transform: translateX(0%) !important;
  }
  .total-consultation .consult-images p {
    margin: 0 3px 0 0 !important;
  }
  .total-consultation .clock {
    margin: 0 10px 0 0;
  }
  .MuiButton-startIcon {
    margin-left: 8px;
    margin-right: -4px;
  }
  nav {
    ul {
      a {
        h5,
        p {
          text-align: right !important;
        }
      }
    }
  }
  .scrollbar-container .MuiList-root .MuiListItemButton-root.Mui-selected {
    border-left: none;
    border-right: 4px solid;
  }
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    margin: 0 0 0 6px !important;
  }
  .css-1h2ex2j-MuiFormLabel-root-MuiInputLabel-root {
    left: unset;
    right: 0;
  }
  .user-data img {
    margin: 0 0 0 5px;
  }
  .add-disease-button {
    margin-right: auto;
    margin-left: unset;
  }
  .btn-group > .btn-group:not(:last-child) > .btn,
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 0.375rem 0.375rem 0;
  }
  .btn-group > .btn-group:not(:first-child) > .btn,
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn {
    border-radius: 0.375rem 0 0 0.375rem;
  }
  .css-jo89mv {
    margin-left: 0;
    margin-right: unset;
  }
  @media (min-width: 900px) {
    .css-88v8r5 {
      margin-right: -240px;
      margin-left: unset;
    }
  }
  .css-88v8r5 {
    margin-left: 20px;
  }
  .css-j204z7-MuiFormControlLabel-root {
    margin-right: -11px;
    margin-left: 16px;
  }
  .me-3 {
    margin-left: 1rem !important;
    margin-right: 0 !important;
  }
  .add-question-btn {
    margin-right: 0;
    margin-left: 50px;
  }
  .element-to-print .box-1 .pds_box table tr:first-child td:last-child {
    border-top-left-radius: 13px;
    border-top-right-radius: 0;
  }
  .element-to-print .box-1 .pds_box table tr:last-child td:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 13px;
  }
  .element-to-print .box-1 .pds_box table tr:first-child th:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 13px;
  }
  .element-to-print .box-1 .pds_box table tr:last-child th:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 13px;
  }
  .modal-header .btn-close {
    margin-left: 0;
  }
}

.map-tree-wrapper {
  direction: ltr;
}
.simple-btn {
  background-color: #fff;
  border: none;
}
.lang-drop {
  width: 150px;
  padding: 5px 0;
  & > div {
    padding: 5px 15px;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.02);
    }
  }
}
.MuiAccordion-root {
  background-color: transparent;
  border: 0;
  &::before {
    display: none;
  }
  .MuiAccordionSummary-root {
    padding: 0;
  }
  .MuiAccordionDetails-root {
    height: 150px;
    overflow-y: scroll;
    padding: 0 10px 0 0;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        background-color: white;
        border-radius: 5px;
        margin-top: 5px;
        padding: 10px;
      }
    }
  }
}
input[type='text']::placeholder {
  color: #034562 !important;
  opacity: 1;
  font-style: italic;
}
.plusCircle {
  display: flex;
  justify-content: center;
  span {
    background-color: #1d8bf4;
    color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: bold;
    cursor: pointer;
  }
}
.questionDropdown {
  margin-bottom: 15px;
}
