.modal-opto {
  // @media (min-width: 576px) {
  //   .modal-dialog {
  //     max-width: 620px !important;
  //   }
  // }
  .modal-content {
    border-radius: 20px;
    border: none !important;
    .modal-header {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      min-height: 73px;
    }
  }
  input {
    height: 48px !important;
    border: 1px solid #dee2e6;
    border-radius: 7px;
  }

  input::placeholder {
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    background-image: url('../../../assets/images/calendar.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    background-image: url('../../../assets/images/time.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
  }
  .Select,
  .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-input {
    border-radius: 7px !important;
  }
  .Select {
    border: 1px solid #dee2e6;
    height: 48px;
  }
  .Select,
  .MuiOutlinedInput-input {
    background-color: #ffffff;
  }
}

.Input_upload_area {
  position: relative;
  height: 100px;
  width: 100%;
  border: 2px dashed #36b0ff !important;
  border-radius: 7px;
  cursor: pointer;
  &.Input_upload_area_2 {
    height: 184px;
  }

  .Input_upload_area_content {
    position: absolute;
    width: 100%;
    height: 100%;
    img {
      width: 24px;
    }
  }
  .Input_upload_area_file-input {
    position: absolute;
    height: 100% !important;
    width: 100%;
    // visibility: hidden;
    opacity: 0;
  }
}
